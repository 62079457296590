import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Route,
  NavLink
} from 'react-router-dom';

import RootComponent from './rootComponent';
import HomeComponent from './home';
import HealthInfoComponent from './health_information/health_info';
import FormComponent from './form';
import SignupComponent from './signup';
import WaitListComponent from './join_waitlist';
import PatientWaitListComponent from './patient_waitlist';
import FinalStep from './finalStep';
import GetStarted from './getStarted';
import ScheduleAppointmentComponent from './schedule_appointment/schedule';
import PersonalInfo from './personalInfo';

import  './sidebar.css';
import bg from '../../assets/images/bg.png';

export default class Sidebar extends Component {
  render(){
    return(
      <Router>
        <Route render={({ location, history }) => (
          <React.Fragment>
            {/* <div className='sidebar'>
              <NavLink to='/schedule'>
              Schedule
              </NavLink>
              <NavLink to='/appointment'>
              View Appointments
              </NavLink>
              <NavLink to='/reimbursment'>
              Reimbursments
              </NavLink>
              <NavLink to='/admin'>
              Admin
              </NavLink>
            </div>*/}
            <div className='content' style={{backgroundImage: `url(${bg})`}}>
              <main>
                <Route path='/schedule' exact component={props => <SignupComponent />} />
                <Route path='/schedule/signup' component={props => <SignupComponent />} />
                <Route path='/schedule/health_information/:dob' component={props => <HealthInfoComponent />} />
                <Route path='/schedule/waitlist' component={props => <WaitListComponent />} />
                <Route path='/schedule/patient_waitlist' component={props => <PatientWaitListComponent />} />
                <Route path='/schedule/appointment' component={props => <ScheduleAppointmentComponent />} />
                <Route path='/schedule/final-step' component={props => <FinalStep />} />
                <Route path='/schedule/get-started' component={props => <GetStarted />} />
                <Route path='/schedule/personal-info' component={props => <PersonalInfo />} />
                <Route path='/appointment' component={props => <FormComponent />} />
                <Route path='/reimbursment' component={props => <HomeComponent />} />
                <Route path='/admin' component={props => <HomeComponent />} />
              </main>
            </div>
          </React.Fragment>
        )}
        />
      </Router>
    )
  }
}
