import React, { Component } from 'react';

import Header from '../ScheduleDirectory/header';
import Sidebar from '../ScheduleDirectory/sidebar';

import './appointment.css';

class AppointmentListComponent extends Component {

  render() {
    return (
      <div className='appointment'>
        <Header />
        <Sidebar />
      </div>
    );
  }
}

export default AppointmentListComponent;
