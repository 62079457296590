import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Route,
  NavLink
} from 'react-router-dom';

import RootComponent from './rootComponent';
import HomeComponent from './home';

import AdminFrame from './admin_frame';

import HealthInfoComponent from './health_information/health_info';
import HealthInfoComponentWi from './health_information_wi/health_info';
import FormComponent from './form';
import SignupComponent from './signup';
import SignupComponentWi from './signup_wi';


import WaitListComponent from './join_waitlist';
import PatientWaitListComponent from './patient_waitlist';
import FinalStep from './finalStep';
import GetStarted from './getStarted';
import ScheduleAppointmentComponent from './schedule_appointment/schedule';
import Landing from './landing'
import LandingWalkin from './landing_walkin'
import Lookup from '../Schedule/personalInfo'
import Login from './login';
import PatientLookup from './patient_lookup';
import PatientLookupWI from './patient_lookup_wi';
import Reimbursement from './reimbursement';

import AppointmentLookup from '../Appointment/appointment';

import VaxCard from '../ScheduleDirectory/vax_card';

import AppointmentGridComponent from '../Appointment/AppointmentGrid/appointment_grid';
import AppoinementDetailsFormWrapper from '../Appointment/Patient/Details/appoinement_details';
import ConfirmAppoinement from '../Appointment/ConfirmAppointment/confirm_appointment';
import ViewAppointment from './schedule_appointment/schedule';

import  './sidebar.css';
import bg from '../../assets/images/bg.png';

export default class Sidebar extends Component {
  render(){
    return(
      <Router>
        <Route render={({ location, history }) => (
          <React.Fragment>
            <div className='sidebar'>
              <NavLink to='/appointment'>
                View Appointments
              </NavLink>
              <NavLink to='/schedule-provider/landing'>
              Schedule
              </NavLink>
              <NavLink to='/schedule-provider/reimbursement'>
              Reimbursements
              </NavLink>
              <NavLink to='/schedule-provider/admin'>
              Admin
              </NavLink>
            </div>
            <div className='content' style={{backgroundImage: `url(${bg})`, marginLeft: '200px' }}>
              <main>
                <Route path='/schedule/personal-info' exact component={props => <Lookup />} />
                <Route path='/schedule-provider' exact component={props => <Login />} />
                <Route path='/schedule-provider/landing-walkin' exact component={props => <LandingWalkin />} />
                <Route path='/schedule-provider/landing' exact component={props => <Landing />} />
                <Route path='/schedule-provider/signup' exact component={props => <SignupComponent />} />
                <Route path='/schedule-provider/signup-wi' exact component={props => <SignupComponentWi />} />
                <Route path='/schedule-provider/health_information' component={props => <HealthInfoComponent />} />
                <Route path='/schedule-provider/health_information_wi' component={props => <HealthInfoComponentWi />} />


                <Route path='/schedule-provider/waitlist' component={props => <WaitListComponent />} />
                <Route path='/schedule-provider/patient_waitlist' component={props => <PatientWaitListComponent />} />
                <Route path='/schedule-provider/appointment' component={props => <ScheduleAppointmentComponent />} />
                <Route path='/schedule-provider/final-step' component={props => <FinalStep />} />
                <Route path='/schedule-provider/get-started' component={props => <GetStarted />} />
                <Route path='/schedule-provider/reimbursement' component={props => <Reimbursement />} />
                <Route path='/schedule-provider/admin' component={props => <AdminFrame />} />
                <Route path='/schedule-provider/patient-lookup' component={props => <PatientLookup />} />
                <Route path='/schedule-provider/patient-lookup-wi' component={props => <PatientLookupWI />} />
                <Route path='/schedule-provider/appointment-lookup' component={props => <AppointmentLookup />} />

                <Route path='/schedule-provider/vaxcard' component={props => <VaxCard />} />

                {/* Appointment Nested Routes*/}
                <Route path='/appointment' exact component={props => <AppointmentGridComponent />} />
                <Route path='/appointment/patientdetails' component={props => <AppoinementDetailsFormWrapper />} />
                <Route path='/appointment/confirm_appointment' component={props => <ConfirmAppoinement />} />
                <Route path='/appointment/checked_appointment' component={props => <PatientWaitListComponent />} />
                <Route path='/appointment/scheduler' component={props => <ViewAppointment />} />

              </main>
            </div>
          </React.Fragment>
        )}
        />
      </Router>
    )
  }
}
