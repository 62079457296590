import React, { Component } from 'react';

import Header from './header';
import Sidebar from './sidebar';
import PharmLogin from './login';

class PharmacyLogin extends Component {
  render() {
    return (
      <div className='schedule'>
        <Header />
        <PharmLogin />
      </div>
    );
  }
}
export default PharmacyLogin;
