import React from 'react';
import { NavLink } from 'react-router-dom';
import { Card, Button, Form } from 'react-bootstrap';
import Header from './header';

import './landing.css';

const Landing = () => {
  return (
    <div className="app">
      <Header />
      <div className="landing d-flex justify-content-center align-items-center">
        <Card className="landing-card">
          <h3 className="text-center mb-3 landing-title">Walk-In Patient</h3>
          <p className="text-center mb-4 landing-description">
            What is the patient here for today?
          </p>
          <div className="d-flex align-items-center justify-content-center">
            <NavLink to="/schedule-provider/signup-wi" className="mr-4">
              <Button variant="light" block>
                First Dose
              </Button>
            </NavLink>
            <NavLink to="/schedule-provider/patient-lookup-wi">
              <Button variant="light" block>
                Second Dose
              </Button>
            </NavLink>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default Landing;
