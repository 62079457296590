import React from 'react';
import { Form } from 'react-bootstrap';
import { Field as FormikField } from 'formik';

const InputComponent = ({
  field,
  form: { touched, errors },
  label,
  ...props
}) => (
  <Form.Group>
    <Form.Control
      invalid={!!(touched[field.name] && errors[field.name])}
      {...field}
      {...props}
      id={field.name}
    />
    {touched[field.name] && errors[field.name] && (
      <Form.Control.Feedback>{errors[field.name]}</Form.Control.Feedback>
    )}
  </Form.Group>
);

export default (props) => <FormikField {...props} component={InputComponent} />;
