import React from 'react';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';

import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup'
import ToggleButton from 'react-bootstrap/ToggleButton'

import BreadcrumbComponent from '../breadcrumbs';

import '../schedule.css';
import './health_info.css';
import {NavLink, Route, Switch} from "react-router-dom";

export default function FormComponent(){
  return(
    <div className='d-flex flex-column justify-content-center align-items-center'>
      <div className='formContainer' style={{paddingBottom: '30px', backgroundColor: '#e9ecef'}}>
        <div className='d-md-block w-100' style={{textAlign: 'center'}}>
          <BreadcrumbComponent />
        </div>
        <Container className='d-flex flex-column justify-content-center align-items-center'>
          <Row>
            <h3 className='headindSchedule'>Health Information</h3>
          </Row>
          <br />
          <Row>
            <p>Please ask the Customer the following questions.</p>
          </Row>
          <Col sm={12}>
            <Form>
              <h6 style={{textAlign: 'center'}}>Do you have insurance?</h6>
              <Form.Group as={Row} className='justify-content-center'>
                <ToggleButtonGroup type='radio' name='hav1'>
                  <ToggleButton className='toggleBtn' name='havea' value={1}>Yes</ToggleButton>
                  <ToggleButton className='toggleBtn' name='have1' value={2}>No</ToggleButton>
                </ToggleButtonGroup>
              </Form.Group>

              <h6 style={{textAlign: 'center'}}>What is your race?</h6>
              <Row className='justify-content-center'>
                <Col sm={6}>
                  <Form.Group className='justify-content-center' controlId="exampleForm.ControlSelect2">
                    <Form.Control as="select">
                      <option>Select</option>
                      <option>American Indian or Alaska Native</option>
                      <option>Asian</option>
                      <option>Black or African American</option>
                      <option>Native Hawaiian or Other Pacific Islander</option>
                      <option>White</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>
              
              
              <h6 style={{textAlign: 'center'}}>Are you Hispanic?</h6>
              <Form.Group as={Row} className='justify-content-center'>
                <ToggleButtonGroup type='radio' name='race'>
                  <ToggleButton className='toggleBtn' name='havea' value={1}>Yes</ToggleButton>
                  <ToggleButton className='toggleBtn' name='have1' value={2}>No</ToggleButton>
                </ToggleButtonGroup>
              </Form.Group>

              <h6 style={{textAlign: 'center'}}>Do you have pre-existing health conditions?</h6>
              <Form.Group as={Row} className='justify-content-center'>
                <ToggleButtonGroup type='radio' name='prehealth'>
                  <ToggleButton className='toggleBtn' name='havea' value={1}>Yes</ToggleButton>
                  <ToggleButton className='toggleBtn' name='have1' value={2}>No</ToggleButton>
                </ToggleButtonGroup>
              </Form.Group>

              <h6 style={{textAlign: 'center'}}>What is your gender?</h6>
              <Row className='justify-content-center'>
                <Col sm={6}>
                  <Form.Group className='justify-content-center' controlId="exampleForm.ControlSelect2">
                    <Form.Control as="select">
                      <option>Select</option>
                      <option>Male</option>
                      <option>Female</option>
                      <option>Prefer not say.</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>

              <h6 style={{textAlign: 'center'}}>Please provide your height and weight</h6>
              <Row className='justify-content-center'>
                <Col sm={4}>
                  <Form.Group className='justify-content-center' controlId="exampleForm.ControlSelect2">
                    <Form.Control as="select">
                      <option>Feet</option>
                      <option>3 ft</option>
                      <option>4 ft</option>
                      <option>5 ft</option>
                      <option>6 ft</option>
                      <option>7 ft</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col sm={4}>
                  <Form.Group className='justify-content-center' controlId="exampleForm.ControlSelect2">
                    <Form.Control as="select">
                      <option>Inches</option>
                      <option>1 in.</option>
                      <option>2 in.</option>
                      <option>3 in.</option>
                      <option>4 in.</option>
                      <option>5 in.</option>
                      <option>6 in.</option>
                      <option>7 in.</option>
                      <option>8 in.</option>
                      <option>9 in.</option>
                      <option>10 in.</option>
                      <option>11 in.</option>
                      <option>12 in.</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col sm={4}>
                  <Form.Group className='justify-content-center' controlId="exampleForm.ControlSelect2">
                    <Form.Control as="select">
                      <option>Pounds</option>
                      <option>100</option>
                      <option>150</option>
                      <option>200</option>
                      <option>250</option>
                      <option>300</option>
                      <option>350</option>
                      <option>400 +</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>
              
              <h6 style={{textAlign: 'center'}}>Are you taking prescription medications?</h6>
              <Form.Group as={Row} className='justify-content-center'>
                <ToggleButtonGroup type='radio' name='prescript'>
                  <ToggleButton className='toggleBtn' name='havea' value={1}>Yes</ToggleButton>
                  <ToggleButton className='toggleBtn' name='have1' value={2}>No</ToggleButton>
                </ToggleButtonGroup>
              </Form.Group>

              <h6 style={{textAlign: 'center'}}>Do you have allergies?</h6>
              <Form.Group as={Row} className='justify-content-center'>
                <ToggleButtonGroup type='radio' name='allergies'>
                  <ToggleButton className='toggleBtn' name='havea' value={1}>Yes</ToggleButton>
                  <ToggleButton className='toggleBtn' name='have1' value={2}>No</ToggleButton>
                </ToggleButtonGroup>
              </Form.Group>

              <h6 style={{textAlign: 'center'}}>Have you had reactions to vaccines in the past?</h6>
              <Form.Group as={Row} className='justify-content-center'>
                <ToggleButtonGroup type='radio' name='vaccine'>
                  <ToggleButton className='toggleBtn' name='havea' value={1}>Yes</ToggleButton>
                  <ToggleButton className='toggleBtn' name='have1' value={2}>No</ToggleButton>
                </ToggleButtonGroup>
              </Form.Group>

              <h6 style={{textAlign: 'center'}}>Have you had another vaccine in the last 3 months?</h6>
              <Form.Group as={Row} className='justify-content-center'>
                <ToggleButtonGroup type='radio' name='vaccine'>
                  <ToggleButton className='toggleBtn' name='havea' value={1}>Yes</ToggleButton>
                  <ToggleButton className='toggleBtn' name='have1' value={2}>No</ToggleButton>
                </ToggleButtonGroup>
              </Form.Group>
              <h6 style={{textAlign: 'center'}}>Are you willing to receive communications<br /> or offers in the future?</h6>
              <Form.Group as={Row} className='justify-content-center'>
                <ToggleButtonGroup type='radio' name='vaccine'>
                  <ToggleButton className='toggleBtn' name='havea' value={1}>Yes</ToggleButton>
                  <ToggleButton className='toggleBtn' name='have1' value={2}>No</ToggleButton>
                </ToggleButtonGroup>
              </Form.Group>
              <h6 style={{textAlign: 'center'}}>What is your occupation?</h6>
              <Row className='justify-content-center'>
                <Col sm={6}>
                  <Form.Group className='justify-content-center' controlId="exampleForm.ControlSelect2">
                    <Form.Control as="select">
                      <option value="" selected="selected" disabled="disabled">Select</option>
                      <optgroup label="Healthcare Practitioners and Technical Occupations:">
                        <option value="1">-  Chiropractor</option>
                        <option value="2">-  Dentist</option>
                        <option value="3">-  Dietitian or Nutritionist</option>
                        <option value="4">-  Optometrist</option>
                        <option value="5">-  Pharmacist</option>
                        <option value="6">-  Physician</option>
                        <option value="7">-  Physician Assistant</option>
                        <option value="8">-  Podiatrist</option>
                        <option value="9">-  Registered Nurse</option>
                        <option value="10">-  Therapist</option>
                        <option value="11">-  Veterinarian</option>
                        <option value="12">-  Health Technologist or Technician</option>
                        <option value="13">-  Other Healthcare Practitioners and Technical Occupation</option>
                      </optgroup>
                      <optgroup label="Healthcare Support Occupations:">
                        <option value="14">-  Nursing, Psychiatric, or Home Health Aide</option>
                        <option value="15">-  Occupational and Physical Therapist Assistant or Aide</option>
                        <option value="16">-  Other Healthcare Support Occupation</option>
                      </optgroup>
                      <optgroup label="Business, Executive, Management, and Financial Occupations:">
                        <option value="17">-  Chief Executive</option>
                        <option value="18">-  General and Operations Manager</option>
                        <option value="19">-  Advertising, Marketing, Promotions, Public Relations, and Sales Manager</option>
                        <option value="20">-  Operations Specialties Manager (e.g., IT or HR Manager)</option>
                        <option value="21">-  Construction Manager</option>
                        <option value="22">-  Engineering Manager</option>
                        <option value="23">-  Accountant, Auditor</option>
                        <option value="24">-  Business Operations or Financial Specialist</option>
                        <option value="25">-  Business Owner</option>
                        <option value="26">-  Other Business, Executive, Management, Financial Occupation</option>
                      </optgroup>
                      <optgroup label="Architecture and Engineering Occupations:">
                        <option value="27">-  Architect, Surveyor, or Cartographer</option>
                        <option value="28">-  Engineer</option>
                        <option value="29">-  Other Architecture and Engineering Occupation</option>
                      </optgroup>
                      <optgroup label="Education, Training, and Library Occupations:">
                        <option value="30">-  Postsecondary Teacher (e.g., College Professor)</option>
                        <option value="31">-  Primary, Secondary, or Special Education School Teacher</option>
                        <option value="32">-  Other Teacher or Instructor</option>
                        <option value="33">-  Other Education, Training, and Library Occupation</option>
                      </optgroup>
                      <optgroup label="Other Professional Occupations:">
                        <option value="34">-  Arts, Design, Entertainment, Sports, and Media Occupations</option>
                        <option value="35">-  Computer Specialist, Mathematical Science</option>
                        <option value="36">-  Counselor, Social Worker, or Other Community and Social Service Specialist</option>
                        <option value="37">-  Lawyer, Judge</option>
                        <option value="38">-  Life Scientist (e.g., Animal, Food, Soil, or Biological Scientist, Zoologist)</option>
                        <option value="39">-  Physical Scientist (e.g., Astronomer, Physicist, Chemist, Hydrologist)</option>
                        <option value="40">-  Religious Worker (e.g., Clergy, Director of Religious Activities or Education)</option>
                        <option value="41">-  Social Scientist and Related Worker</option>
                        <option value="42">-  Other Professional Occupation</option>
                      </optgroup>
                      <optgroup label="Office and Administrative Support Occupations:">
                        <option value="43">-  Supervisor of Administrative Support Workers</option>
                        <option value="44">-  Financial Clerk</option>
                        <option value="45">-  Secretary or Administrative Assistant</option>
                        <option value="46">-  Material Recording, Scheduling, and Dispatching Worker</option>
                        <option value="47">-  Other Office and Administrative Support Occupation</option>
                      </optgroup>
                      <optgroup label="Services Occupations:">
                        <option value="48">-  Protective Service (e.g., Fire Fighting, Police Officer, Correctional Officer)</option>
                        <option value="49">-  Chef or Head Cook</option>
                        <option value="50">-  Cook or Food Preparation Worker</option>
                        <option value="51">-  Food and Beverage Serving Worker (e.g., Bartender, Waiter, Waitress)</option>
                        <option value="52">-  Building and Grounds Cleaning and Maintenance</option>
                        <option value="53">-  Personal Care and Service (e.g., Hairdresser, Flight Attendant, Concierge)</option>
                        <option value="54">-  Sales Supervisor, Retail Sales</option>
                        <option value="55">-  Retail Sales Worker</option>
                        <option value="56">-  Insurance Sales Agent</option>
                        <option value="57">-  Sales Representative</option>
                        <option value="58">-  Real Estate Sales Agent</option>
                        <option value="59">-  Other Services Occupation</option>
                      </optgroup>
                      <optgroup label="Agriculture, Maintenance, Repair, and Skilled Crafts Occupations:">
                        <option value="60">-  Construction and Extraction (e.g., Construction Laborer, Electrician)</option>
                        <option value="61">-  Farming, Fishing, and Forestry</option>
                        <option value="62">-  Installation, Maintenance, and Repair</option>
                        <option value="63">-  Production Occupations</option>
                        <option value="64">-  Other Agriculture, Maintenance, Repair, and Skilled Crafts Occupation</option>
                      </optgroup>
                      <optgroup label="Transportation Occupations:">
                        <option value="65">-  Aircraft Pilot or Flight Engineer</option>
                        <option value="66">-  Motor Vehicle Operator (e.g., Ambulance, Bus, Taxi, or Truck Driver)</option>
                        <option value="67">-  Other Transportation Occupation</option>
                      </optgroup>
                      <optgroup label="Other Occupations:">
                        <option value="68">-  Military</option>
                        <option value="69">-  Homemaker</option>
                        <option value="70">-  Other Occupation</option>
                        <option value="71">-  Don't Know</option>
                        <option value="72">-  Not Applicable</option>
                      </optgroup>
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>
              
              <Row className='justify-content-between mb-10'>
                <NavLink to='/schedule-provider'>
                  <Button variant='outline' type="submit" className='scheduleOutline'>Back</Button>
                </NavLink>
                <NavLink to='/appointment/'>
                  <Button type="submit" className='scheduleButton'>Check In</Button>
                </NavLink>
              </Row>
            </Form>
          </Col>

        </Container>

      </div>
    </div>
  )
}