import React, { Component } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import Scheduler from 'devextreme-react/scheduler';
// import { browserHistory } from 'react-router-dom';
import { withRouter } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle, faSearch } from '@fortawesome/free-solid-svg-icons'

import './appointment_grid.css';

class AppointmentGridComponent extends Component {
  constructor(props){
    super(props)
    this.state = {
      redirect: false,
      data: [
        {time: '09:00am', data: [{first_name: 'Lael', last_name:'Morrison', upid: 'NLZ58LCC0OA', confirmation: '345038', status:'Checked-in'},{first_name: 'Gil', last_name:'Lopez', upid: 'AGG58LMW7NU', confirmation: '446454', status:'Not Checked-in'},{first_name: 'Ryder', last_name:'Whitney', upid: 'OOK48EXZ6ZD', confirmation: '373472', status:'Checked-in'},{first_name: 'Cain', last_name:'Pruitt', upid: 'HLL12JBI1UW', confirmation: '525483', status:'Not Checked-in'},{first_name: 'Alika', last_name:'Finley', upid: 'NDZ53KTE2VW', confirmation: '368775', status:'Not Checked-in'},{first_name: 'Tim', last_name:'Stanley', upid: 'XIP57WHR1QJ', confirmation: '273966', status:'Not Checked-in'}]},
        {time: '10:00am', data: [{first_name: 'Fredericka', last_name:'Barton', upid: 'HOM10SFF3WQs', confirmation: '794260', status:'Checked-in'},{first_name: 'Quintessa', last_name:'Hampton', upid: 'IHP09VIN0YL', confirmation: '931762', status:'Checked-in'},{first_name: 'Sean', last_name:'Marshall', upid: 'OBL40PZK2ZX', confirmation: '843988', status:'Checked-in'},{first_name: 'Hanna', last_name:'Saunders', upid: 'XIP57WHR1QJ', confirmation: '273966', status:'Not Checked-in'}]},
        {time: '11:00am', data: [{first_name: 'Dahlia', last_name:'Ruiz', upid: 'CGK81BLD0ZO', confirmation: '768875', status:'Not Checked-in'},{first_name: 'Zena', last_name:'Lewis', upid: 'BMI36ARG7EH', confirmation: '665322', status:'Checked-in'},{first_name: 'Ocean', last_name:'Ellis', upid: 'WGO66RPT9FN', confirmation: '234504', status:'Checked-in'},{first_name: 'Vaughan', last_name:'Morales', upid: 'BVZ54JMK9KX', confirmation: '479630', status:'Not  Checked-in'},{first_name: 'Keelie', last_name:'Gray', upid: 'PJV21JSB3UP', confirmation: '344390', status:'Checked-in'}]},
        {time: '12:00am', data: [{first_name: 'Alden', last_name:'Petty', upid: 'XCM39SKH2ID', confirmation: '322710', status:'Not Checked-in'},{first_name: 'Zephr', last_name:'Payne', upid: 'JVT12JEJ3KG', confirmation: '126653', status:'Checked-in'},{first_name: 'Hector', last_name:'Moran', upid: 'GVR40LZR1QJ', confirmation: '652509', status:'Checked-in'},{first_name: 'Karly', last_name:'Mccarty', upid: 'ZJO93FZX3FI', confirmation: '654851', status:'Not Checked-in'},{first_name: 'Victoria', last_name:'Santiago', upid: 'SAD73ZGO4VR', confirmation: '868544', status:'Checked-in'}]},
        {time: '13:00pm', data: [{first_name: 'Howard', last_name:'Winters', upid: 'DNH01BTQ6IY', confirmation: '743789', status:'Not Checked-in'},{first_name: 'Tyler', last_name:'Mann', upid: 'KXY03IHW4DZ', confirmation: '397404', status:'Not Checked-in'},{first_name: 'Conan', last_name:'Joseph', upid: '5435452', confirmation: '270572', status:'Not Checked-in'},{first_name: 'Yen', last_name:'Banks', upid: 'WOT48JRF3JZ', confirmation: '541263', status:'Checked-in'},{first_name: 'Autumn', last_name:'Leach', upid: 'AYM86JPR2GO', confirmation: '704332', status:'Checked-in'}]},
        {time: '14:00pm', data: [{first_name: 'Camden', last_name:'Slater', upid: 'NOL89JMD1QN', confirmation: '619954', status:'Not Checked-in'},{first_name: 'Nevada', last_name:'Kline', upid: 'ABL77GLY1SM', confirmation: '336767', status:'Not Checked-in'},{first_name: 'Jana', last_name:'Oneal', upid: 'WML78BQV5OC', confirmation: '239493', status:'Not Checked-in'},{first_name: 'Tyrone', last_name:'Hughes', upid: 'CMM70GZG1FH', confirmation: '668672', status:'Checked-in'},{first_name: 'Castor', last_name:'Cherry', upid: 'DCA42LCY4DR', confirmation: '205070', status:'Checked-in'}]},
        {time: '15:00pm', data: [{first_name: 'Timothy', last_name:'Stuart', upid: 'PZK63CPX9RA', confirmation: '994692', status:'Not Checked-in'},{first_name: 'Price', last_name:'Pickett', upid: 'ILR16SRV5YO', confirmation: '593513', status:'Not Checked-in'},{first_name: 'Jane', last_name:'Fuller', upid: 'BBE99ISQ7XF', confirmation: '656637', status:'Not Checked-in'},{first_name: 'Flynn', last_name:'Guy', upid: 'TUG28TBC9FL', confirmation: '971714', status:'Checked-in'},{first_name: 'John', last_name:'Appleseed', upid: 'KVM12UPX5HM', confirmation: '628750', status:'Checked-in'}]}
      ]
    }
  }

  getDetails = (e,theStatus) => {
    console.log(e['target']['innerText']);

    if (!e['target']['innerText'].includes('Not')) {
      this.props.history.push('/appointment/confirm_appointment');
    } else {
      this.props.history.push('/appointment/patientdetails');
    }

  }
/*
  getDetailsAdmin = (e) => {
    console.log(e['target']);
    this.props.history.push('/');
  }
*/
  render() {
    return (
      <div className='customTable'>
        <div className='d-flex align-items-center  searchbar'>
          <div className='container'>
              <Scheduler
                timeZone='America/Los_Angeles'
                formatDate='EEEE, d of MMM, yyyy HH:mm'
              >
              </Scheduler>
            <div className='walkInButton'><a href={"/schedule-provider/landing-walkin/"}><button>Walk-In Patient</button></a>

            </div>
              <div className='search-control'>
                <Form.Group className='col-sm-3'>
                  <span className='search-icon'>
                    <FontAwesomeIcon icon={faSearch} size='sm' />
                  </span>
                  <Form.Control size='sm' type='text' placeholder='Search' className='searchField'/>
                </Form.Group>
              </div>

          </div>
        </div>
        <div className='w-100 mw-100 d-flex justify-content-center align-items-center gridHeading'>
          <Col sm={1} className='headerText' style={{textAlign: 'center'}}>Time</Col>
          <Col sm={11}>
            <Row className='userHeadingRow'>
              <Col className='headerText'>FIRST NAME</Col>
              <Col className='headerText'>LAST NAME</Col>
              <Col className='headerText'>CUSTOMER ID</Col>
              <Col className='headerText'>CONFIRMATION</Col>
              <Col className='headerText'>STATUS</Col>
            </Row>
          </Col>
        </div>
        {
          this.state.data.map((appointment, index) =>{
            return(
              <div key={index} className='w-100 mw-100 d-flex justify-content-center align-items-center dataTable'>
                <Col sm={1} className='headerCol' style={{textAlign: 'center'}}>{appointment.time}</Col>
                <Col sm={11} style={{borderLeft: '1px solid #E2E2E2'}}>
                  {
                    appointment.data.map((user, index) =>{
                      return(
                        <Row key={index} className='userRow'  onDoubleClick={this.getDetails}>
                          <Col className='headerCol'>{user.first_name}</Col>
                          <Col className='headerCol'>{user.last_name}</Col>
                          <Col className='headerCol'>{user.upid}</Col>
                          <Col className='headerCol'>{user.confirmation}</Col>
                          <Col className={user.status === 'Checked-in' ? 'activeHeaderCol' : 'headerCol'}><FontAwesomeIcon icon={faCircle} size='xs' color={user.status === 'Checked-in' ? '#46BD0C' : '#4D4D4D'} /> {user.status}</Col>
                        </Row>
                      )
                    })
                  }
                </Col>
              </div>
            )
          })
        }
      </div>
    );
  }
}

export default withRouter (AppointmentGridComponent);