import React from 'react';
import { Button, Form } from 'react-bootstrap';

export default function PatientWaitListComponent(){
  return(
    <div className='d-flex justify-content-center align-items-center' style={{ height: '800px' }}>
      <div className='scheduleBox'>
        <h3 className='mt-3 headindSchedule'>Vaccination Complete.</h3>
        <br />
        <p>All patient records have been updated.</p>

        <Form.Group className='justify-content-between'>
            <a href={"/appointment"}><Button className='patientBtn'>Close</Button></a>
            <hr />
            <a href={"/schedule-provider/appointment/"}>Schedule Follow-Up</a> | <a href={"/schedule-provider/vaxcard"}>View or Print VaxCard</a>
        </Form.Group>
      </div>
    </div>
  )
}