import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';

import Landing from './components/Landing/landing';
import Schedule from './components/Schedule/schedule';
import Complete from './components/Schedule/complete';
import ScheduleDirectory from './components/ScheduleDirectory/schedule';
import Pharmacy from './components/ScheduleDirectory/pharmacy_login';
import AppointmentListComponent from './components/Appointment/appointment';

export default class App extends Component {
  render() {
    return (
      <div className='App'>
        <Router>
          <Switch>
            <Route exact path='/'>
              <Landing />
            </Route>
            <Route path='/schedule'>
              <Schedule />
            </Route>
            <Route path='/schedule-provider'>
              <ScheduleDirectory />
            </Route>
            <Route path='/pharmacy-login'>
              <Pharmacy />
            </Route>
            <Route path='/schedule/complete'>
              <Complete />
            </Route>
            <Route path='/appointment'>
              <AppointmentListComponent />
            </Route>
          </Switch>
        </Router>  
      </div>
    );
  }
}