import React, { Component } from 'react';

import Form from './form_wi';

export default class SignupComponentWi extends Component {
  render() {
    return (
      <div className='container'>  
        <Form />
      </div>
    );
  }
}