import React from 'react';
import { NavLink } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Card, Alert, Button } from 'react-bootstrap';
import FormInput from './formInput';
import Header from './header';

import gradientImg from '../../assets/images/login-gradient.png';
import pexelImg from '../../assets/images/login-pexels.png';

import './login.css';

const loginSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
  password: Yup.string().min(8, 'Too short').required('Required'),
});

const backgroundImg = {
  backgroundImage: `url(${gradientImg})`,
};

const Login = () => {
  const loading = false;
  const error = '';
  const handleSubmit = (values) => {
    console.log(values);
  };

  return (
    <div className="app">
      <Header />
      <div className="row m-0 login-page">
        <div
          className="col-7 p-0 d-flex justify-content-center align-items-center"
          style={backgroundImg}
        >
          <Card className="login-card">
            <h3 className="text-center mb-2 login-title">Sign in</h3>
            <p className="text-center mb-4 login-description">
              Orange Plaza Pharmacy
            </p>
            {error && <Alert color="danger">{error}</Alert>}
            <Formik
              initialValues={{
                email: '',
                password: '',
              }}
              validationSchema={loginSchema}
              onSubmit={handleSubmit}
            >
              {() => (
                <Form className="login-form">
                  <FormInput
                    name="email"
                    placeholder="Username or Email"
                    type="email"
                    disabled={loading}
                    autoComplete="off"
                  />
                  <FormInput
                    name="password"
                    placeholder="Password"
                    type="password"
                    disabled={loading}
                    autoComplete="off"
                  />
                  <a className="login-forgot" href="javascript:void(0)">
                    Forgot Password
                  </a>
                  <NavLink to="/appointment">
                    <Button
                      className="mt-4 login-submit"
                      color="primary"
                      type="submit"
                      block
                      disabled={loading}
                    >
                      Login
                    </Button>
                  </NavLink>
                </Form>
              )}
            </Formik>
          </Card>
        </div>
        <div className="col-5 p-0">
          <img src={pexelImg} className="w-100" alt="pexels" />
        </div>
      </div>
    </div>
  );
};
export default Login;
