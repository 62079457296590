import React, { Component } from 'react';
import { Button } from 'react-bootstrap';

import './now.css';

class OtherSection extends Component {
  render() {
    return (
      <section className="learn-about-section">
        <div className='thumbnail'>
          <div className='caption text-white'>
            <h3>Learn About Vaccine Safety</h3>
            <p className='nowDesc'>Over 875 Million of people have been recieved ths COVID vaccine worldwide.</p>
            <Button variant='light'>Learn</Button>
          </div>
        </div>
      </section>
    );
  }
}

export default OtherSection;