import React from 'react';
import { Container, Row, Button, Modal, Form, Breadcrumb } from 'react-bootstrap';
import {
	NavLink
  } from 'react-router-dom';

export default function HomeComponent(){
	return(
		<div className='d-flex justify-content-center align-items-center' style={{  background: 'linear-gradient(to bottom left, #e1d8f3 0%, #b1d0ec 100%)'}}>
			
		  <div className='formContainer'>
		  <div className='mt-5'>
			<Breadcrumb bg='transparent'>
			  <Breadcrumb.Item href='/schedule'>GETTING STARTED</Breadcrumb.Item>
			  <Breadcrumb.Item active>HEALTH INFORMATION</Breadcrumb.Item>
			  <Breadcrumb.Item></Breadcrumb.Item>
			</Breadcrumb>   
		  </div>
			<Container className='mb-10'>
			  <h3 className='mt-3 headindSchedule text-center'>Health Information</h3>
			  <br />
			  <p class="font-weight-bold color-grey text-center">Tell Us More About Yourself</p>
				<Form.Check 
					type="switch"
					id="have"
					label="Have"
				/>
				<Form.Group className="custom-div">
					<Form.Label>Race or</Form.Label>
					<Form.Control size="sm" as="select">
						<option>Select</option>
					</Form.Control>
				</Form.Group>
				<Form.Check 
					type="switch"
					id="hispanic"
					label="Hispanic"
				/>
				<Form.Check 
					type="switch"
					id="conditions"
					label="Pre-Existing Health Conditions"
				/>
				<Form.Group className="custom-div">
					<Form.Label>Gende</Form.Label>
					<Form.Control size="sm" as="select">
						<option>Select</option>
					</Form.Control>
				</Form.Group>
				<p class="text-center">Height &amp;</p>
				<div class="row custom-div">
					<div class="col-lg-4 col-md-4 col-sm-12">
						<Form.Control size="sm" as="select">
							<option>Height</option>
						</Form.Control>
					</div>
					<div class="col-lg-4 col-md-4 col-sm-12">
						<Form.Control size="sm" as="select">
							<option>Height</option>
						</Form.Control>
					</div>
					<div class="col-lg-4 col-md-4 col-sm-12">
						<Form.Control size="sm" as="select">
							<option>Weight</option>
						</Form.Control>
					</div>
          		</div>
				<Form.Check 
					type="switch"
					id="prescription"
					label="Taking Prescription"
				/>
				<Form.Check 
					type="switch"
					id="allergies"
					label="Allergies"
				/>
				<Form.Check 
					type="switch"
					id="vaccine"
					label="Vaccine"
				/>
				<Form.Check 
					type="switch"
					id="past"
					label="In the past 3 months have you taken other vaccines or"
				/>
				<Form.Check 
					type="switch"
					id="willing"
					label="Willing To Receive Marketing"
				/>
				<Form.Group className="custom-div">
					<Form.Label>Occupation</Form.Label>
					<Form.Control size="sm" as="select">
						<option>Select</option>
					</Form.Control>
				</Form.Group>
			  <Row className='padding-15'>
				<div class="col-lg-6 col-md-6 col-sm-12 text-left">
					<NavLink to='/appointment'>
						<Button variant="outline-primary">Bac</Button>
					</NavLink>
				</div>
				<div class="col-lg-6 col-md-6 col-sm-12 text-right">
					<NavLink to='/admin'>
						<Button className='scheduleBtn'>Next</Button>
					</NavLink>
				</div>
			  </Row>
			</Container>
		  </div>
		</div>
	  )
}