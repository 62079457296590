import React from 'react';
import { Button, Form } from 'react-bootstrap';

export default function PatientWaitListComponent(){
  return(
    <div className='d-flex justify-content-center align-items-center' style={{ height: '800px' }}>
      <div className='scheduleBox'>
        <h3 className='mt-3 headindSchedule'>Tim Is On Waiting</h3>
        <br />
        <p>More vaccines become available every day.</p>
        <p>We will contact you as soon as you become eligible, we will contact you and get you scheduled right away!
        </p>
        <Form.Group className='justify-content-between'>
            <Button className='patientBtn'>Done</Button>
        </Form.Group>
      </div>
    </div>
  )
}