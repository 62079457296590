import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Scheduler, { Resource } from 'devextreme-react/scheduler';
import { data, resourcesData } from './data.js';
import AppointmentModal from './modal.js';


import './schedule.css'

const currentDate = new Date(2021, 4, 25);
const views = [{
  type: 'month',
  name: 'Auto Mode',
  maxAppointmentsPerCell: 'auto'
}, {
  type: 'month',
  name: 'Unlimited Mode',
  maxAppointmentsPerCell: 'unlimited'
}, {
  type: 'month',
  name: 'Numeric Mode',
  maxAppointmentsPerCell: 2
}];


class ScheduleAppointmentComponent extends Component {
  
  constructor(props){
    super(props)
    this.state = {
      modal: false
    }
  }
  getAppointmentTemplate = (e) => {
    return(
      <div>
      <p onClick={() => this.selectData(e.appointmentData.text)}>9am</p>
      <p onClick={() => this.selectData(e.appointmentData.text)}>10am</p>
      <p onClick={() => this.selectData(e.appointmentData.text)}>11am</p>
      <p onClick={() => this.selectData(6)}>12pm</p>
      </div>
    )
  }

  selectData = (e) => {
    return;
  }

  overrideDefaultPopup = (e) => {
  }

  onAppointmentFormOpening = (data) =>{
    data.popup.hide();
    this.setState({modal: true})
  }

  getAppointmentPopup = (appointmentData, createNewAppointment, currentAppointmentData) => {
    debugger
    return (
      <h1>Modal Show</h1>
    )
  }

  render() {
    return (
      <Container>
        <Col sm={12}>
          <AppointmentModal showModal={this.state.modal} closeModal={() => this.setState({modal: false})} />
          <div clasName='d-flex justify-content-center align-items-center' style={{backgroundColor: '#fff', paddingTop: '13px', margin: '13px 15px 0px', borderTop: '1px solid lightgrey'}}>
            <Row>
              <Col sm={1}></Col>
              <Col sm={11}><h3 style={{color: '#174E6D'}}>Let's Get You Scheduled</h3></Col>
            </Row>
            <Row>
              <Col sm={1}>
              </Col>
              <Col sm={11}>
              <p>Select a date below to view available times</p>
              </Col>
            </Row>
          </div>
        </Col>
        <Col sm={12}>
          <Container className='d-flex flex-column justify-content-center align-items-center pb-20'>
            <Scheduler
              timeZone="America/Los_Angeles"
              dataSource={data}
              views={views}
              defaultCurrentView="Auto Mode"
              defaultCurrentDate={currentDate}
              height={700}
              appointmentTooltipRender={this.getAppointmentTemplate}
              onContentReady={this.onContentReady}
              onAppointmentDeleted={this.hideSchedulerTooltip}
              onAppointmentFormOpening={this.onAppointmentFormOpening}
            >
              <Resource
                dataSource={resourcesData}
                fieldExpr="roomId"
                label="Room"
              />
            </Scheduler>
          </Container>
        </Col>
      </Container>
    );
  }
}

export default ScheduleAppointmentComponent;
