import React, { Component } from 'react';
import { Image, Button } from 'react-bootstrap';

import nowImage from '../../assets/images/2.jpg';
import './now.css';

class NowSection extends Component {
  render() {
    return (
      <section>
        <div className='thumbnail'>
          <Image className='w-100 imageOverlay' src={nowImage} fluid/>
          <div className='n-caption text-white'>
            <h3>Are You Eligible to Get Vaccinated?</h3>
            <p className='nowDesc'>Find out if you are eligible and schedule a test and get on waiting list. Testing</p>
            <a href="/schedule"><Button variant='light' className='nowBtn'>Get Started</Button></a>
          </div>
        </div>
      </section>
    );
  }
}

export default NowSection;
