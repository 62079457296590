import React from 'react';
import { NavLink } from 'react-router-dom';
import { Card, Button, Form } from 'react-bootstrap';
import Header from './header';

import './landing.css';

const Landing = () => {
  return (
    <div className="app">

      <iframe name="adminframe" src="https://admin.vaxwerks.com/index.php/user/login" width="100%" height="1000" frameBorder="0" scrolling="auto"
              className="frame-area">
      </iframe>
    </div>
  );
};

export default Landing;
