import React, { useState } from 'react';
import { Card, Alert, Button } from 'react-bootstrap';
import Header from './header';
import CustomTable from './table';

import './personalInfo.css';

let data = [];
let n = 1000;
for (let i = 0; i < n; i++) {
	data.push({
		id: i + 1,
		first: 'John',
		last: 'Appleseed',
		ipid: 5435454,
		code: 123456
	});
}
const columns = [
	{
		dataField: 'id',
		text: 'No',
		sort: true
	},
  {
    dataField: 'first',
    text: 'FIRST NAME',
    sort: true,
  },
  {
    dataField: 'last',
    text: 'LAST NAME',
    sort: true,
  },
  {
    dataField: 'ipid',
    text: 'UPID',
    sort: true,
  },
	{
		dataField: 'code',
		text: 'CONFIRMATION CODE',
		sort: true
	}
];

const PersonalInfo = () => {
  return (
    <div className="app">
      <Header />
      <div className="personal-info">
        <h3 className="personal-title mb-4">Patient Look Up</h3>
        <CustomTable data={data} columns={columns} />
      </div>
    </div>
  );
};

export default PersonalInfo;
