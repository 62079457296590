import React, { Component } from 'react';
import { connect } from "react-redux";
import { indexAction, modalAction }  from '../../actions/index';

import Header from './header';
import NowSection from './now';
import WtsSection from './wtsSection';
import OtherSection from './other';
import TestimonialSection from './testimonials';
import UserData from './userData';
import PhoneModal from './phoneModal';

class Landing extends Component {
  render() {
    return (
      <div className='landing'>
        <Header />
        <PhoneModal showModal={this.props.activeModal.modal} closeModal={() => this.props.modalAction(false)} />
        {
          this.props.activeState.landing && (
            <UserData />
          )
        }
        <NowSection showModal={() => this.props.modalAction(true)}/>
        <br />
        <WtsSection />
        <OtherSection />
        
        <br />

        <TestimonialSection />
        
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ...state
});
const mapDispatchToProps = dispatch => ({
  indexAction: (payload) => dispatch(indexAction(payload)),
  modalAction: (payload) => dispatch(modalAction(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(Landing);
