export const indexAction = (payload) => {
  return {
    type: 'landing',
    payload
  }
}

export const modalAction = (payload) => {
  return {
    type: 'modal',
    payload
  }
}
