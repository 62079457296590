import React from 'react';
import { Container, Row, Button, Modal, Form } from 'react-bootstrap';

import './modal.css';
import {NavLink} from "react-router-dom";

function AppointmentModal({showModal, closeModal}) {
  return (
    <Modal show={showModal} aria-labelledby='contained-modal-title-vcenter' centered>
      <Modal.Header className='customModal'>
        <Modal.Title id='contained-modal-title-vcenter' className='justify-content-centered modalHeader'>
          Schedule This Appointment?
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row style={{'justify-content': 'center'}}>
              We will send a confirmation code<br /> to customer’s phone and e-mail.
          </Row>
          <Row className='mt-3'  style={{'justify-content': 'center'}}>
              <div className='d-flex flex-column justify-content-center align-items-center container' style={{backgroundColor: '#E2E2E2', borderRadius: '5px'}}>
                <p  style={{'justify-content': 'center'}}><strong>March 4, 2021 at 10:00am</strong><br />
                Orange Plaza<br />
                123 Main Street, AnyTown, CA</p>
              </div>  
          </Row>
          <Row style={{'justify-content': 'center'}}>
            Patient ID: 1234
          </Row>
          <Row className='justify-content-center'>
            Authorization Code: 12345 
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer className='customModal'>
        <Button onClick={closeModal} variant='outline-primary modalOutline' size='lg'>Cancel</Button>
          <NavLink to='/schedule-provider/final-step/'>
            <Button onClick={closeModal} className='ml-2 modalBtn' size='lg'>Confirm</Button>
          </NavLink>
      </Modal.Footer>
    </Modal>
  )
}
export default AppointmentModal;