import React, { useState } from 'react';
import { Card, Alert, Button } from 'react-bootstrap';
import Header from './header';

import gradientImg from '../../assets/images/login-gradient.png';
import maskImg from '../../assets/images/mask-group.png';

import './getStarted.css';

const backgroundImg = {
  backgroundImage: `url(${gradientImg})`,
};

const Complete = () => {
  const [title, setTitle] = useState('Join Our Waitlist?');
  const [description, setDescription] = useState(
      'As more vaccines become available, we will contact as soon as you become eligible and get you scheduled right away!'
  );
  const [isSchedule, setIsSchedule] = useState(false);

  const completeSchedule = () => {
    setTitle('You’re On The Waiting List');
    setIsSchedule(true);
  }

  return (
      <div className="app">
        <Header />
        <div className="row m-0 get-started">
          <div
              className="col-12 col-md-7 p-0 d-flex justify-content-center align-items-center"
              style={backgroundImg}
          >
            <Card className="get-started-card">
              <h3 className="text-center mb-3 get-started-title">{title}</h3>
              <p className="text-left mb-4 get-started-description">
                {description}
              </p>
              {!isSchedule && (
                  <div className="d-flex align-items-center justify-content-center">
                    <Button
                        className="mt-4 mr-4"
                        variant="light"
                        block
                        onClick={completeSchedule}
                    >
                      Yes
                    </Button>
                    <Button className="mt-4" variant="light" block>
                      No
                    </Button>
                  </div>
              )}
              {isSchedule && (
                  <div className="get-started-schedule">
                    <Button
                        className="mt-4 get-started-submit"
                        variant="primary"
                        block
                    >
                      Done
                    </Button>
                  </div>
              )}
            </Card>
          </div>
          <div className="col-12 col-md-5 p-0">
            <img src={maskImg} className="w-100" alt="pexels" />
          </div>
        </div>
      </div>
  );
};

export default Complete;
