import React from 'react';
import { Container, Row, Button, Modal, Form } from 'react-bootstrap';

import './wts.css';

function PhoneModal({showModal, closeModal}) {
  return (
    <Modal show={showModal} aria-labelledby='contained-modal-title-vcenter'>
      <Modal.Header className='customModal'>
        <Modal.Title id='contained-modal-title-vcenter' className='justify-content-centered'>
          Lets Get Started
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row style={{'justify-content': 'center'}}>
            Enter you Phone Number to receive an authorization code.
          </Row>
          <Row className='mt-3'>
              <Form.Control size='sm' type='text' placeholder='Phone' />
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer className='customModal'>
        <Button onClick={closeModal} variant='outline-primary' size='lg'>Cancel</Button>
        <Button onClick={closeModal} className='ml-2' size='lg'>Get</Button>
      </Modal.Footer>
    </Modal>
  )
}
export default PhoneModal;