import React from 'react';
import {Button, Form, Nav} from 'react-bootstrap';
import vaxcard from '../../assets/images/vaxwerks_card_qr.png';
import vaxpdf from '../../assets/pdf/vaxwerks_card_tstanley.pdf';

export default function VaxCardComponent(){
  return(
    <div className='d-flex justify-content-center align-items-center' style={{ height: '800px' }}>
      <div>

        <br />

          <img
              src={vaxcard}
              width='90%'

              className='d-inline-block align-top'
              alt='Vax Card - Tim Stanley'
          />
    <hr />
        <Form.Group className='justify-content-between'>
            <a href={vaxpdf}><Button className='patientBtn'>Download</Button></a>&nbsp;&nbsp;&nbsp;
            <a href={vaxpdf}><Button className='patientBtn'>Print</Button></a>&nbsp;&nbsp;&nbsp;
        </Form.Group>
      </div>
    </div>
  )
}