import React, { useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import './table.css';

const MySearch = (props) => {
  let input;
  const handleChange = () => {
    props.onSearch(input.value);
  };
  return (
    <div class="search-control">
      <span className="search-icon">
        <FontAwesomeIcon icon={faSearch} size="xs" />
      </span>
      <input
        className="form-control"
        ref={(n) => (input = n)}
        type="text"
        placeholder="search"
        onChange={handleChange}
      />
    </div>
  );
};

const rowStyle2 = (row, rowIndex) => {
  const style = {};
  if (row.id % 2) {
    style.backgroundColor = '#FCFCFC';
  } else {
    style.backgroundColor = '#bbbbbb';
  }
  return style;
};

const CustomTable = (props) => {
  const { SearchBar } = Search;
  return (
    <div className="custom-table">
      <ToolkitProvider
        keyField="id"
        data={props.data}
        columns={props.columns}
        search
        pagination={paginationFactory()}
      >
        {(props) => (
          <div>
            <MySearch {...props.searchProps} />
            <hr />
            <BootstrapTable {...props.baseProps} rowStyle={ rowStyle2 } pagination={ paginationFactory() } />
          </div>
        )}
      </ToolkitProvider>
    </div>
  );
};

export default CustomTable;
