import React, { useState } from 'react';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import BreadcrumbComponent from './breadcrumbs';

import './schedule.css';

const FormComponent = () => {
  const [dob, setDob] = useState('Year');

  const handleDobChange = (e) => {
    setDob(e.target.value);
  };

  return (
    <div className="d-flex flex-column justify-content-center align-items-center">
      <div className="formContainer" style={{ backgroundColor: '#e9ecef' }}>
        <div className="d-md-block w-100" style={{ textAlign: 'center' }}>
          <BreadcrumbComponent />
        </div>
        <Container
          className="d-flex flex-column mt-20 justify-content-center align-items-center"
          style={{ backgroundColor: '#e9ecef' }}
        >
          <Row>
            <h3 className="headindSchedule">Sign Up for Your Vaccine</h3>
          </Row>
          <br />
          <Row>
            <h5>Basic Information</h5>
          </Row>
          <Col sm={12}>
            <Form>
              <Form.Group>
                <Form.Control
                  size="sm"
                  type="text"
                  placeholder="First Name"
                  className="w-200"
                />
              </Form.Group>
              <Form.Group>
                <Form.Control
                  size="sm"
                  type="text"
                  placeholder="Last Name"
                  className="w-200"
                />
              </Form.Group>
              <Form.Group>
                <Form.Control
                  size="sm"
                  type="text"
                  placeholder="E-mail Address"
                  className="w-200"
                />
              </Form.Group>
              <Form.Group>
                <Form.Control
                  size="sm"
                  type="text"
                  placeholder="Phone Number"
                  className="w-200"
                />
              </Form.Group>
              <Form.Group>
                <Form.Control
                  size="sm"
                  type="password"
                  placeholder="Password"
                  className="w-200"
                />
              </Form.Group>
              <Form.Group>
                <Form.Control
                  size="sm"
                  type="password"
                  placeholder="Confirm Password"
                  className="w-200"
                />
              </Form.Group>
              <br />
              <h5 style={{ textAlign: 'center' }}>Date of Birth</h5>
              <Row className="justify-content-center">
                <Col sm={4}>
                  <Form.Group
                    className="justify-content-center"
                    controlId="exampleForm.ControlSelect2"
                  >
                    <Form.Control as="select">
                      <option>Day</option>
                      <option>1</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                      <option>5</option>
                      <option>6</option>
                      <option>7</option>
                      <option>8</option>
                      <option>9</option>
                      <option>10</option>
                      <option>11</option>
                      <option>12</option>
                      <option>13</option>
                      <option>14</option>
                      <option>15</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col sm={4}>
                  <Form.Group
                    className="justify-content-center"
                    controlId="exampleForm.ControlSelect2"
                  >
                    <Form.Control as="select">
                      <option>Month</option>
                      <option>Jan - 1</option>
                      <option>Feb - 2</option>
                      <option>Mar - 3</option>
                      <option>Apr - 4</option>
                      <option>May - 5</option>
                      <option>June - 6</option>
                      <option>July - 7</option>
                      <option>Aug - 8</option>
                      <option>Sep - 9</option>
                      <option>Oct -10</option>
                      <option>Nov - 11</option>
                      <option>Dev - 12</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col sm={4}>
                  <Form.Group
                    className="justify-content-center"
                    controlId="exampleForm.ControlSelect2"
                  >
                    <Form.Control
                      as="select"
                      value={dob}
                      onChange={handleDobChange}
                    >
                      <option>Year</option>
                      <option value="1922">1922</option>
                      <option value="1923">1923</option>
                      <option value="1924">1924</option>
                      <option value="1925">1925</option>
                      <option value="1926">1926</option>
                      <option value="1927">1927</option>
                      <option value="1928">1928</option>
                      <option value="1929">1929</option>
                      <option value="1930">1930</option>
                      <option value="1931">1931</option>
                      <option value="1932">1932</option>
                      <option value="1933">1933</option>
                      <option value="1934">1934</option>
                      <option value="1935">1935</option>
                      <option value="1936">1936</option>
                      <option value="1937">1937</option>
                      <option value="1938">1938</option>
                      <option value="1939">1939</option>
                      <option value="1940">1940</option>
                      <option value="1941">1941</option>
                      <option value="1942">1942</option>
                      <option value="1943">1943</option>
                      <option value="1944">1944</option>
                      <option value="1945">1945</option>
                      <option value="1946">1946</option>
                      <option value="1947">1947</option>
                      <option value="1948">1948</option>
                      <option value="1949">1949</option>
                      <option value="1950">1950</option>
                      <option value="1951">1951</option>
                      <option value="1952">1952</option>
                      <option value="1953">1953</option>
                      <option value="1954">1954</option>
                      <option value="1955">1955</option>
                      <option value="1956">1956</option>
                      <option value="1957">1957</option>
                      <option value="1958">1958</option>
                      <option value="1959">1959</option>
                      <option value="1960">1960</option>
                      <option value="1961">1961</option>
                      <option value="1962">1962</option>
                      <option value="1963">1963</option>
                      <option value="1964">1964</option>
                      <option value="1965">1965</option>
                      <option value="1966">1966</option>
                      <option value="1967">1967</option>
                      <option value="1968">1968</option>
                      <option value="1969">1969</option>
                      <option value="1970">1970</option>
                      <option value="1971">1971</option>
                      <option value="1972">1972</option>
                      <option value="1973">1973</option>
                      <option value="1974">1974</option>
                      <option value="1975">1975</option>
                      <option value="1976">1976</option>
                      <option value="1977">1977</option>
                      <option value="1978">1978</option>
                      <option value="1979">1979</option>
                      <option value="1980">1980</option>
                      <option value="1981">1981</option>
                      <option value="1982">1982</option>
                      <option value="1983">1983</option>
                      <option value="1984">1984</option>
                      <option value="1985">1985</option>
                      <option value="1986">1986</option>
                      <option value="1987">1987</option>
                      <option value="1988">1988</option>
                      <option value="1989">1989</option>
                      <option value="1990">1990</option>
                      <option value="1991">1991</option>
                      <option value="1992">1992</option>
                      <option value="1993">1993</option>
                      <option value="1994">1994</option>
                      <option value="1995">1995</option>
                      <option value="1996">1996</option>
                      <option value="1997">1997</option>
                      <option value="1998">1998</option>
                      <option value="1999">1999</option>
                      <option value="2000">2000</option>
                      <option value="2001">2001</option>
                      <option value="2002">2002</option>
                      <option value="2003">2003</option>
                      <option value="2004">2004</option>
                      <option value="2005">2005</option>
                      <option value="2006">2006</option>
                      <option value="2007">2007</option>
                      <option value="2008">2008</option>
                      <option value="2009">2009</option>
                      <option value="2010">2010</option>
                      <option value="2011">2011</option>
                      <option value="2012">2012</option>
                      <option value="2013">2013</option>
                      <option value="2014">2014</option>
                      <option value="2015">2015</option>
                      <option value="2016">2016</option>
                      <option value="2017">2017</option>
                      <option value="2018">2018</option>
                      <option value="2019">2019</option>
                      <option value="2020">2020</option>
                      <option value="2021">2021</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>
              <br />
              <h5 style={{ textAlign: 'center' }}>Residence Information</h5>
              <Form.Group>
                <Form.Control
                  size="sm"
                  type="text"
                  placeholder="Street Address"
                  className="w-200"
                />
              </Form.Group>
              <Form.Group>
                <Form.Control
                  size="sm"
                  type="text"
                  placeholder="City"
                  className="w-200"
                />
              </Form.Group>
              <Form.Group controlId="statePicker">
                <Form.Control as='select' className='w-200' >
                  <option value="">Choose State...</option>
                  <option value="AL">Alabama (AL)</option>
                  <option value="AK">Alaska (AK)</option>
                  <option value="AZ">Arizona (AZ)</option>
                  <option value="AR">Arkansas (AR)</option>
                  <option value="CA">California (CA)</option>
                  <option value="CO">Colorado (CO)</option>
                  <option value="CT">Connecticut (CT)</option>
                  <option value="DE">Delaware (DE)</option>
                  <option value="DC">District Of Columbia (DC)</option>
                  <option value="FL">Florida (FL)</option>
                  <option value="GA">Georgia (GA)</option>
                  <option value="HI">Hawaii (HI)</option>
                  <option value="ID">Idaho (ID)</option>
                  <option value="IL">Illinois (IL)</option>
                  <option value="IN">Indiana (IN)</option>
                  <option value="IA">Iowa (IA)</option>
                  <option value="KS">Kansas (KS)</option>
                  <option value="KY">Kentucky (KY)</option>
                  <option value="LA">Louisiana (LA)</option>
                  <option value="ME">Maine (ME)</option>
                  <option value="MD">Maryland (MD)</option>
                  <option value="MA">Massachusetts (MA)</option>
                  <option value="MI">Michigan (MI)</option>
                  <option value="MN">Minnesota (MN)</option>
                  <option value="MS">Mississippi (MS)</option>
                  <option value="MO">Missouri (MO)</option>
                  <option value="MT">Montana (MT)</option>
                  <option value="NE">Nebraska (NE)</option>
                  <option value="NV">Nevada (NV)</option>
                  <option value="NH">New Hampshire (NH)</option>
                  <option value="NJ">New Jersey (NJ)</option>
                  <option value="NM">New Mexico (NM)</option>
                  <option value="NY">New York (NY)</option>
                  <option value="NC">North Carolina (NC)</option>
                  <option value="ND">North Dakota (ND)</option>
                  <option value="OH">Ohio (OH)</option>
                  <option value="OK">Oklahoma (OK)</option>
                  <option value="OR">Oregon (OR)</option>
                  <option value="PA">Pennsylvania (PA)</option>
                  <option value="RI">Rhode Island (RI)</option>
                  <option value="SC">South Carolina (SC)</option>
                  <option value="SD">South Dakota (SD)</option>
                  <option value="TN">Tennessee (TN)</option>
                  <option value="TX">Texas (TX)</option>
                  <option value="UT">Utah (UT)</option>
                  <option value="VT">Vermont</option>
                  <option value="VA">Virginia</option>
                  <option value="WA">Washington</option>
                  <option value="WV">West Virginia</option>
                  <option value="WI">Wisconsin</option>
                  <option value="WY">Wyoming</option>
                </Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Control
                  size="sm"
                  type="text"
                  placeholder="Zip"
                  className="w-200"
                />
              </Form.Group>
              <Form.Group as={Row}>
                <Col className="float-right">
                  <NavLink
                    to={`/schedule/health_information/${dob}`}
                    location={{
                      state: { dob },
                    }}
                  >
                    <Button type="submit" className="scheduleBtn">
                      Next
                    </Button>
                  </NavLink>
                </Col>
              </Form.Group>
            </Form>
          </Col>
        </Container>
      </div>
    </div>
  );
};

export default FormComponent;
