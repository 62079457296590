import React from 'react';
import { Container, Row, Button, Modal } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import './modals.css';


const UploadRecords = ({
  showModal,
  modalType,
  headerText,
  bodyText,
  buttonText,
  buttonFunction
}) => {
  const {acceptedFiles, getRootProps, getInputProps} = useDropzone();

  const formatBytes = (bytes) => {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  }
  
  const files = acceptedFiles.map(file => (
    <p key={file.path}>
      <b>{file.path}</b><br/>
      {formatBytes(file.size)}
    </p>
  ));

  return (
    <Modal
      show={showModal}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="upload-records"
    >
      <Modal.Header className="customModal">
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="justify-content-centered modalHeader"
        >
          { headerText }
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {
          modalType === "dropzone" && (
          <Container>
              <div {...getRootProps({className: 'dropzone text-center'})}>
                <input {...getInputProps()} />
                <Row style={{ justifyContent: 'center' }}>
                  Drag and Drop Files
                  <br />
                  or
                </Row>
                <Button variant="outline-primary">Browse</Button>
              </div>
            <aside>
              <ul>{files}</ul>
            </aside>
          </Container>
          )
        }
        {
          bodyText  && (
          <Container className="text-center">
            <p>{ bodyText }</p>
          </Container>
          )
        }
      </Modal.Body>
      <Modal.Footer className="customModal">
        {
          modalType === "submitted" && (
            <Button
            onClick={buttonFunction}
            variant="outline-primary"
            size="lg"
          >
            Download
          </Button>
          )
        }
        <Button
          onClick={buttonFunction}
          variant="primary"
          size="lg"
        >
          { buttonText }
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default UploadRecords;
