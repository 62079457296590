import React, { Component } from 'react';
import { Nav, Navbar, Button } from 'react-bootstrap';

import { connect } from 'react-redux';
import { indexAction } from '../../actions/index';

import './schedule.css';
import logo from '../../assets/images/logo1.png';

class Header extends Component {

  render() {
    return (
      <div className='header'> 
        <Navbar bg='white' variant='light' fixed='top'>
          <Navbar.Brand href='#home' style={{color: 'lightgrey', fontSize: '12px', marginTop: '2px'}}>
            <Nav.Link className='text-secondary' href='/'>
            <img
              src={logo}

              height='30'
              className='d-inline-block align-top'
              alt='logo'
            /></Nav.Link>
          </Navbar.Brand>
          <Nav className='mr-auto'>
            <Nav.Link className='text-secondary' href='#home'>|</Nav.Link>
            <Nav.Link className='text-secondary' href='#home' style={{fontSize:'12px'}}>POWERED BY <strong>Vax</strong>Werks</Nav.Link>
          </Nav>
          <Navbar.Collapse className='justify-content-end'>
            {
              this.props.activeState.landing ? (
                <Button variant='light'>Logout</Button>
              ) : (
                <Nav className='justify-content-end'>
                  <Nav.Link href='#signup' className='text-dark mr-sm-5'>
                    <Button variant='light'>Sign In</Button>
                  </Nav.Link>

                </Nav>
              )

            }
          </Navbar.Collapse>
        </Navbar>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ...state
});
const mapDispatchToProps = dispatch => ({
  indexAction: (payload) => dispatch(indexAction(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
