import React, { Component } from 'react';

import Form from './form';

export default class SignupComponent extends Component {
  render() {
    return (
      <div className='container'>  
        <Form />
      </div>
    );
  }
}