import React, { Component } from 'react';
import { Row, Col, Button, Form } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

import '../Patient/Details/schedule.css';

export default class ConfirmAppoinement extends Component {
  render() {
    return (
      <div className='container mh-100 d-flex justify-content-center align-items-start' style={{height: '700px', width: '500px', backgroundColor: '#e9ecef',paddingTop:'13px'}}>  
        <div className='d-flex flex-column align-items-center' style={{marginTop: '80px'}}>
          <Row>
            <h3 className='headindSchedule'>Tim Stanley</h3>
          </Row>
          <p>Confirmation Code: 273966</p>
          <Row>
            <Form className='w-500'>
              <Col sm={12}>

                <Form.Control as="select">
                  <option>Select Brand</option>
                  <option>Moderna</option>
                  <option>Pfizer</option>
                  <option>Novavax</option>
                  <option>Johnson & Johnson</option>
                </Form.Control>
              </Col>
              <br />
              <Col sm={12}>
                <Form.Group>
                  <Form.Control as="select">
                    <option>Select Dose</option>
                    <option>50 MG.</option>
                  </Form.Control>
                </Form.Group>
                <br />
                <Form.Group>
                  <Form.Control size='sm' type='text' placeholder='Unique Code'/>
                  <a href={"#"} >Scan Barcode</a>
                </Form.Group>

                <hr />
              </Col>
              <Col sm={12}>
                <Form.Group>
                  <Form.Control size='sm' type='text' placeholder='Notes'/>
                </Form.Group>
              </Col>
            </Form>
          </Row>
          <Row>
            <div className='mt-3'>
              <NavLink to='/appointment/checked_appointment'>
                <Button className='ml-3 patientBtn'>Next</Button>
              </NavLink>
            </div>
          </Row>
          <Row>
            <div className='mt-3' style={{color: '#02567D'}}>
              <NavLink to='/appointment/confirm_appointment'>

              </NavLink>
            </div>
          </Row>
        </div>
      </div>
    );
  }
}