import React from 'react';
import { Button, Form } from 'react-bootstrap';

export default function WaitListComponent(){
  return(
    <div className='d-flex justify-content-center align-items-center' style={{ height: '800px' }}>
      <div className='scheduleBox'>
        <h3 className='mt-3 headindSchedule'>Join our WaitList?</h3>
        <br />
        <p>As more vaccines become available.</p>
        <p>we will contact you as soon as you become eligible and get you schedule right away!</p>
        <Form.Group className='justify-content-between'>
            <Button className='waitlistBtn'>Yes </Button>
            <Button className='ml-3 waitlistBtn'>No</Button>
        </Form.Group>
      </div>
    </div>
  )
}