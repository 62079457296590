import React, { Component } from 'react';
import { Col, Container, Row, Card } from 'react-bootstrap';

import eligible from '../../assets/images/1.png';
import location from '../../assets/images/4.png';
import './wts.css';
import './now.css';

class WtsSection extends Component {
  render() {
    return (
      <div className='wts'>
        <Container className="text-left">
            <h3>Where to start</h3>
            <p>Find out more about COVID vaccines</p>
          <Row>
            <Col sm={6}>
              <Card>
                <Card.Img className='imgRadius' variant='top' src={eligible} rounded="true" />
                <Card.Body>
                  <Card.Title className='text-left'>Who's Eligible?</Card.Title>
                  <Card.Text className='text-left'>Find out if you are eligible and schedule a test or get on the waiting list</Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col sm={6}>
              <Card>
                <Card.Img className='imgRadius' variant='top' src={location} rounded="true" />
                <Card.Body>
                  <Card.Title className='text-left'>Orange Pharmacy</Card.Title>
                  <Card.Text className='text-left'>Your Partner in good health</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default WtsSection;
