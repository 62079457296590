import React, { useState } from 'react';
import { Card, Alert, Button } from 'react-bootstrap';
import Header from './header';

import gradientImg from '../../assets/images/login-gradient.png';

import './finalStep.css';
import {NavLink, Route, Switch} from "react-router-dom";

const backgroundImg = {
  backgroundImage: `url(${gradientImg})`,
};

const FinalStep = () => {
  return (
    <div className="app">
      <Header />
      <div className="row m-0 final-step">
        <div
          className="col-12 d-flex justify-content-center align-items-center flex-column"
          style={backgroundImg}
        >

          <Card className="final-step-card">
            <p className="text-center mb-2 get-started-description">
              You're All Set
            </p>
            <h3 className="text-center mb-3 get-started-title">
              Tim Stanley
            </h3>
            <p className="text-center mb-4 get-started-description">For</p>
            <div className="final-step-address">
              <p className="mb-0"><strong>March, 23 2021 at 10:00pm</strong></p>
              <p className="mb-0">Orange plaza pharmacy</p>
              <p className="mb-0">123 Main Street</p>
              <p className="mb-0">Anytown, CA 94111</p>
            </div>
            <div className="final-step-code">
              <p className="text-left mb-0">
                Customer ID: <span>5321</span>
              </p>
              <p className="text-left mb-0">
                Confirmation Code: <span>12345</span>
              </p>
            </div>
          </Card>
          <div className="d-flex align-items-center justify-content-between mt-3 final-step-buttons">
            <Button className="cancel-btn" variant="light" block>
              Cancel Appointment
            </Button>
            <div className="d-flex">

              <NavLink to='/schedule/appointment'>
              <Button className="send-btn mr-2" variant="light">
                Edit
              </Button>
              </NavLink>
              <Button className="print-btn" variant="light">
                Print
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinalStep;
