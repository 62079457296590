import React, { Component } from 'react';
import { Col, Container, Row, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'

//import cloud from '../assets/images/TechStackWordCloud.svg';
import black from '../../assets/images/black.jpeg';
import './wts.css';

class TestmonialSection extends Component {
  render() {
    return (
      <Container className="testimonials-component text-left pb-1">
        <h3>Testimonials</h3>
        <Row>
          <Col md={4} className="mb-4">
            <Card>
              <Card.Img className='mt-5 roundImage' variant='top' src={black} />
              <Card.Body>
                <Card.Title>
                  <FontAwesomeIcon icon={faStar} size='xs' color='#ffc107'/>
                  <FontAwesomeIcon icon={faStar} size='xs' color='#ffc107'/>
                  <FontAwesomeIcon icon={faStar} size='xs' color='#ffc107'/>
                  <FontAwesomeIcon icon={faStar} size='xs' color='#ffc107'/>
                  <FontAwesomeIcon icon={faStar} size='xs' color='#ffc107'/>
                </Card.Title>
                <Card.Text className='testimonialText'>
                  I booked my COVID Vaccine and it was so easy! I appreciate you,  Thank You!!
                </Card.Text>
              </Card.Body>
              <Card.Footer style={{backgroundColor: 'white'}}>
                <Card.Text className='testimonialText'>
                  Anonymous - Los Angeles, CA
                </Card.Text>
              </Card.Footer>
            </Card>
          </Col>
          <Col md={4} className="mb-4">
            <Card>
              <Card.Img className='mt-5 roundImage' variant='top' src={black} />
              <Card.Body>
                <Card.Title>
                  <FontAwesomeIcon icon={faStar} size='xs' color='#ffc107'/>
                  <FontAwesomeIcon icon={faStar} size='xs' color='#ffc107'/>
                  <FontAwesomeIcon icon={faStar} size='xs' color='#ffc107'/>
                  <FontAwesomeIcon icon={faStar} size='xs' color='#ffc107'/>
                  <FontAwesomeIcon icon={faStar} size='xs' color='#ffc107'/>
                </Card.Title>
                <Card.Text className='testimonialText'>
                  So cool you let me book all our family for Covid vaccines, Thank you!!
                </Card.Text>
              </Card.Body>
              <Card.Footer style={{backgroundColor: 'white'}}>
                <Card.Text className='testimonialText'>
                  Bill - Tampa, FL
                </Card.Text>
              </Card.Footer>
            </Card>
          </Col>
          <Col md={4} className="mb-4">
            <Card>
              <Card.Img className='mt-5 roundImage' variant='top' src={black} />
              <Card.Body>
                <Card.Title>
                  <FontAwesomeIcon icon={faStar} size='xs' color='#ffc107'/>
                  <FontAwesomeIcon icon={faStar} size='xs' color='#ffc107'/>
                  <FontAwesomeIcon icon={faStar} size='xs' color='#ffc107'/>
                  <FontAwesomeIcon icon={faStar} size='xs' color='#ffc107'/>
                  <FontAwesomeIcon icon={faStar} size='xs' color='#ffc107'/>
                </Card.Title>
                <Card.Text className='testimonialText'>
                  Thank you for booking my Covid shot -- I feel so much safer now!
                </Card.Text>
              </Card.Body>
              <Card.Footer style={{backgroundColor: 'white'}}>
                <Card.Text className='testimonialText'>
                  Jenny - Phoenix, AZ
                </Card.Text>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
        <Row className='ml-1 mb-5 row'>
          <a href='#seemore' style={{color: '#ffc107'}}>See More</a>
        </Row>
      </Container>
    );
  }
}

export default TestmonialSection;
