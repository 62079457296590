export const data = [
  {
    text: '5 appointments available',
    roomId: 4,
    startDate: new Date('2021-04-30T06:00:00.000Z'),
    endDate: new Date('2021-04-30T07:30:00.000Z')
  }, {
    text: '3 appointments available',
    roomId: 4,
    startDate: new Date('2021-02-30T18:30:00.000Z'),
    endDate: new Date('2021-02-30T21:15:00.000Z')
  }, {
    text: '5 appointments available',
    roomId: 4,
    startDate: new Date('2021-07-30T20:15:00.000Z'),
    endDate: new Date('2021-07-30T23:15:00.000Z')
  }, {
    text: '5 appointments available',
    roomId: 4,
    startDate: new Date('2021-09-30T23:45:00.000Z'),
    endDate: new Date('2021-09-30T18:15:00.000Z')
  }, {
    text: '15 appointments available',
    roomId: 2,
    startDate: new Date('2021-05-04T05:15:00.000Z'),
    endDate: new Date('2021-05-04T07:45:00.000Z')
  }, {
    text: '35 appointments available',
    roomId: 3,
    startDate: new Date('2021-12-04T19:00:00.000Z'),
    endDate: new Date('2021-15-04T20:45:00.000Z')
  }, {
    text: '3 appointments available',
    roomId: 3,
    startDate: new Date('2021-25-04T05:15:00.000Z'),
    endDate: new Date('2021-25-04T06:00:00.000Z')
  }, {
    text: '4 appointments available',
    roomId: 1,
    startDate: new Date('2021-22-05T08:45:00.000Z'),
    endDate: new Date('2021-22-05T10:45:00.000Z')
  }, {
    text: '6 appointments available',
    roomId: 4,
    startDate: new Date('2021-05-13T21:00:00.000Z'),
    endDate: new Date('2021-05-13T23:45:00.000Z')
  }
];

export const resourcesData = [
  {
    text: 'Room 401',
    id: 1,
    color: '#fff'
  }, {
    text: 'Room 402',
    id: 2,
    color: '#fff'
  }, {
    text: 'Room 403',
    id: 3,
    color: '#fff'
  }, {
    text: 'Room 407',
    id: 4,
    color: '#fff'
  }, {
    text: 'Room 409',
    id: 5,
    color: '#fff'
  }
];
