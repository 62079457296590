import React, { Component } from 'react';

import Form from './form';

export default class HealthInfoComponent extends Component {
  render() {
    return (
      <div className=''>  
        <Form />
      </div>
    );
  }
}