import React, { Component } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Image from 'react-bootstrap/Image'

import black from '../../assets/images/black.jpeg';
import './now.css';

class UserData extends Component {
  render() {
    return (
      <Container className='navbar-fixed-top userContainer mb-4' fluid>
        <Row>
          <Col sm={6}>
            <div className='d-flex flex-column justify-content-center'>
              <div className='d-flex flex-row'>
                <div>
                  <Image className='roundImage' src={black} roundedCircle />
                </div>
                <div className='ml-2'>
                  <h3>John Apps</h3>
                  <p>123 Main Street, New York, NY 10030</p>
                </div>
              </div>
            </div>
          </Col>
          <Col sm={6}>
            <div className='d-flex flex-column'>
              <div className='d-flex flex-row justify-content-left'>
                <p>You have appointment scheduled</p>
                <h4 className='ml-4'>March 24, 2021</h4>
              </div>
              <div className='d-flex flex-row justify-content-left'>
                <p>123 Main Street, New York, NY 10030</p>
              </div>
              <div className='d-flex flex-row justify-content-left'>
                <a href='#reschedule'>Reschedule</a>
                <a href='#reschedule' className='ml-2 text-danger'>Cancel</a>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      
    );
  }
}

export default UserData;
