import React from 'react';
import { Breadcrumb } from 'react-bootstrap';

export default function BreadcrumbComponent(){
  return(
    <Breadcrumb>
      <Breadcrumb.Item href='/appointment' style={{color: '#02567D'}}>Getting Started</Breadcrumb.Item>
      <Breadcrumb.Item href='/health' active>
        Health Information
      </Breadcrumb.Item>
    </Breadcrumb>
  )
}