import React, { Component } from 'react';

import Header from './header';
import Sidebar from './sidebar';

class Schedule extends Component {
  render() {
    return (
      <div className='schedule'>
        <Header />
        <Sidebar />
      </div>
    );
  }
}
export default Schedule;

// const mapStateToProps = state => ({
//   ...state
// });
// const mapDispatchToProps = dispatch => ({
//   indexAction: (payload) => dispatch(indexAction(payload)),
//   modalAction: (payload) => dispatch(modalAction(payload))
// });

// export default connect(mapStateToProps, mapDispatchToProps)(Schedule);
